<template>
  <div v-if="selectedComponent && selectedCompliance">
    <b-modal
      id="generate-compliance-requirements-modal"
      :title="`Generate compliance requirements for ${selectedComponent.context.details.name}.${selectedCompliance.name}`"
      size="xl"
      class="p-0"
    >
      <div>
        <h4>
          Generate Compliance Requirements
        </h4>
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="scroll-area-idea-entities"
          style="max-height: 10rem"
        />

        <h6 class="mt-2 text-primary">
          1. Select target Specification
        </h6>
        <SpecificationPicker v-model="targetSpecification" />

        <h6 class="mt-2 text-primary">
          2. Select target Requirement
        </h6>
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="scroll-area-idea-entities"
          style="max-height: 20rem"
        >
          <b-table
            responsive
            striped
            hover
            selectable
            show-empty
            select-mode="single"
            class="position-relative"
            :fields="requirementTableFields"
            :items="requirementsList"
            @row-selected="onRowSelected"
          >
            <template #cell(object_text)="data">
              <p v-html="data.item.object_text" />
            </template>
          </b-table>
        </vue-perfect-scrollbar>

        <h6 class="mt-2 text-primary">
          3. Relationship to target Requirement
        </h6>
        <b-row>
          <b-col>
            <b-form-radio-group v-slot="{ ariaDescribedby }">
              <b-form-radio v-model="relationToTarget" :aria-describedby="ariaDescribedby" name="some-radios" value="before">
                Before
              </b-form-radio>
              <b-form-radio v-model="relationToTarget" :aria-describedby="ariaDescribedby" name="some-radios" value="after">
                After
              </b-form-radio>
              <b-form-radio v-model="relationToTarget" :aria-describedby="ariaDescribedby" name="some-radios" value="child">
                Child
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>

        <hr>
        <h5 v-if="targetRequirement" class="text-center mt-1">
          Generate requirements
          <!--<span class="text-primary"> {{ sourceRequirement.properties.display_id }}</span>-->
          <span class="text-danger text-uppercase"> - {{ relationToTarget === 'child' ? 'as a child below' : relationToTarget }} - </span>
          <span class="text-primary">{{ targetRequirement.display_id }}</span>
        </h5>
      </div>

      <template v-slot:modal-footer>
        <b-button variant="outline-secondary" @click="onClose">
          Discard
        </b-button>
        <b-button
          :disabled="!targetSpecification || !targetRequirement || isGenerating || isLoading"
          variant="success"
          @click="onSubmit"
        >
          Generate Requirements
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import axiosIns from '@/libs/axios'
import { computed, ref, watch } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import SpecificationPicker from '@/components/Specifications/SpecificationPicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'GenerateRequirementsComplianceModal',
  components: {
    VuePerfectScrollbar,
    SpecificationPicker,
  },
  setup(props, context) {
    const perfectScrollbarSettings = { maxScrollbarLength: 60 }
    const requirementTableFields = [
      { key: 'section', label: 'Section', thStyle: 'width: 2rem' },
      { key: 'display_id', label: 'Display ID', thStyle: 'width: 12rem' },
      { key: 'priority', label: 'Priority', thStyle: 'width: 12rem' },
      { key: 'object_text', label: 'Object Text' },
    ]

    const isLoading = ref(false)
    const isGenerating = ref(false)

    const selectedComponent = computed(() => store.state.domainModel.selected_entity2)
    const selectedCompliance = computed(() => store.state.domainModel.selected_compliance)
    const targetSpecification = ref(null)
    const targetRequirement = ref(null)
    const relationToTarget = ref('after')

    const requirementsList = ref([])
    watch(targetSpecification, val => {
      requirementsList.value = []
      if (targetSpecification.value !== null) {
        axiosIns
          .get(`/api/v2/specifications/${targetSpecification.value.id}/requirements`)
          .then(({ data }) => {
            Object.entries(data).forEach(entry => {
              requirementsList.value.push(entry[1])
            })
          })
      }
    })
    const onRowSelected = rows => {
      targetRequirement.value = rows[0]?.id ? rows[0] : null
    }

    const onShow = () => {
      targetSpecification.value = null
      targetRequirement.value = null
      relationToTarget.value = 'after'
    }
    const onSubmit = () => {
      isGenerating.value = true
      store
        .dispatch('domainModel/generateComplianceRequirements', {
          componentId: selectedComponent.value.context.details.id,
          complianceId: selectedCompliance.value.id,
          targetSpecId: targetSpecification.value,
          targetReqId: targetRequirement.value.id,
          relationToTarget: relationToTarget.value,
        })
        .finally(() => onClose())
    }
    const onClose = () => {
      isLoading.value = false
      isGenerating.value = false
      targetSpecification.value = null
      targetRequirement.value = null
      relationToTarget.value = 'after'
      context.root.$bvModal.hide('generate-compliance-requirements-modal')
    }

    return {
      perfectScrollbarSettings,
      requirementTableFields,
      requirementsList,

      isLoading,
      isGenerating,

      selectedComponent,
      selectedCompliance,
      targetSpecification,
      targetRequirement,
      relationToTarget,

      onShow,
      onClose,
      onSubmit,
      onRowSelected,
    }
  },
}
</script>
