<template>
  <b-modal
    id="edit-property-modal"
    title="Update Component Property"
    size="lg"
    @ok="submitFunction"
  >
    <form ref="form">
      <b-row>
        <b-col cols="12">
          <b-form-group label="Name" label-for="input-1">
            <b-form-input
              id="input-1"
              v-model="payload.name"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group label="Type" label-for="input-2">
            <b-form-select
              id="input-2"
              v-model="payload.type"
              :options="type_options"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Unit" label-for="input-3">
            <b-form-input
              id="input-3"
              v-model="payload.unit"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Value" label-for="input-v">
            <b-form-input
              id="input-v"
              v-model="payload.value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Minimum" label-for="input-6">
            <b-form-input
              id="input-6"
              v-model="payload.min"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Maximum" label-for="input-5">
            <b-form-input
              id="input-5"
              v-model="payload.max"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="add_entity_select_2">Classification</label>
          <b-form-select
            id="add_entity_select_2"
            v-model="payload.classification"
            :options="securityClassifications"
          />
        </b-col>
      </b-row>
    </form>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-secondary" @click="cancel()">
        Discard
      </b-button>

      <b-button variant="success" :disabled="loading_status" @click="ok()">
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Updating Property...
        </span>
        <span v-else>
          Update Property
        </span>
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { BCol, BFormGroup, BRow } from 'bootstrap-vue'

export default {
  components: { BFormGroup, BCol, BRow },
  data() {
    return {
      payload: {
        model: this.$store.state.model.id,
        name: '',
        type: 'User Defined',
        unit: '',
        required: false,
        unique: false,
        validation: '',
        min: '',
        max: '',
        value: '',
        default: '',
        classification: '',
      },
      type_options: ['User Defined', 'String', 'Integer', 'Real', 'Date', 'Time', 'Date/Time', 'List', 'Tuple', 'Dictionary'],

      loading_status: false,

    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2', 'selected_property']),
    ...mapGetters({
      securityClassifications: 'constants/securityClassifications',
    }),
  },
  watch: {
    selected_property(newVal) {
      this.fillFields(newVal)
    },
  },
  mounted() {
    this.fillFields(this.selected_property)
  },
  methods: {
    fillFields(val) {
      const cur = this.payload
      cur.name = val.name
      cur.value = val.value || ''
      cur.type = val.type
      cur.unit = val.unit
      cur.min = val.min
      cur.max = val.max
      cur.classification = val.classification || this.$store.state.constants.defaultSecurityClassification.id
    },
    submitFunction(e) {
      e.preventDefault()
      this.loading_status = true
      console.log('Selected Entity: ', this.selected_entity2)
      const selectedId = this.selected_entity2.context.details.id
      this.$http.post(`/api/v2/domain_model/update_property/${this.selected_property.id}`, this.payload).then(() => {
        this.loading_status = false
        this.$bvModal.hide('edit-property-modal')
        this.$store.dispatch('domainModel/selectEntity2', selectedId)
        this.$emit('edited', this.payload)
      })
    },
  },
}
</script>
