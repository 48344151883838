<template>
  <b-list-group-item
    class="entity"
  >
    <!--<pre>{{ item }}</pre>-->
    <div class="d-flex flex-column">
      <!-- Text -->
      <div id="records-item-text" class="d-flex flex-row justify-content-between" style="gap: 1rem;">
          <p class="text-primary font-small-3 mb-0">
            Phase: <span class="text-muted font-small-3">{{ recordsItem.phase }}</span> | Evidence links: <span class="text-muted font-small-3">{{ (recordsItem.evidence ? recordsItem.evidence.length : 0) }}</span>
          </p>
          <p class="font-weight-bolder text-normal">
            {{ recordsItem.method }}
          </p>

        <!-- Icons -->
        <b-button-group id="records-item-icons" size="sm">
          <b-button
            variant="flat-primary"
            size="sm"
            @click="updateRecord"
          >
            <feather-icon icon="EditIcon" size="16" />
          </b-button>
          <b-button
            variant="flat-secondary"
            size="sm"
            @click="createEvidenceLink"
          >
            <feather-icon icon="LinkIcon" size="16" />
          </b-button>
          <b-button
            variant="flat-danger"
            size="sm"
            @click="deleteRecord"
          >
            <feather-icon icon="TrashIcon" size="16" />
          </b-button>
        </b-button-group>
      </div>

      <div>
        <p class="font-small-3" v-html="recordsItem.statement" />
      </div>
    </div>
  </b-list-group-item>
</template>

<script>
export default {
  name: 'RecordListGroupItem',
  props: {
    recordsItem: {
      type: Object,
      required: true,
    },
  },
  methods: {
    createEvidenceLink() {
      this.$emit('link-evidence-with-qualification-record', this.recordsItem.id)
    },
    updateRecord() {
      this.$emit('update-qualification-record', this.recordsItem.id)
    },
    deleteRecord() {
      this.$emit('delete-qualification-record', this.recordsItem.id)
    },
  },
}
</script>

<style lang="scss">
  #records-item-icons > button {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
</style>
