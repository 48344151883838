<template>
  <b-modal
    id="add-relation-modal"
    title="Add Relation"
    size="lg"
    @show="onShow"
    @ok="submitFunction"
  >
    {{ selected_entity2.context.details.name }}
    <b-form-group label="Relation Name:" label-for="rel-name-add">
      <b-form-input
        id="rel-name-add"
        v-model="name"
        list="common-rels-list"
        required="true"
      />
      <datalist id="common-rels-list">
        <option>Accountable for</option>
        <option>Associated with</option>
        <option>Allocated to</option>
        <option>Benefits</option>
        <option>Benefits from</option>
        <option>Consulted on</option>
        <option>Consumes</option>
        <option>Consumed by</option>
        <option>Contributes to</option>
        <option>Enables</option>
        <option>Enabled by</option>
        <option>Hosts</option>
        <option>Hosted on</option>
        <option>Implements</option>
        <option>Implemented by</option>
        <option>Implemented on</option>
        <option>Implemented at</option>
        <option>Informed of</option>
        <option>Performs</option>
        <option>Performed by</option>
        <option>Produces</option>
        <option>Produced by</option>
        <option>Provides</option>
        <option>Provided by</option>
        <option>Realises</option>
        <option>Realised by</option>
        <option>Responsible for</option>
        <option>Uses</option>
      </datalist>
    </b-form-group>
    <b-row>
      <b-col>
        <b-form-group label="Source Entity:" label-for="input-2">
          <b-form-input
            id="input-2"
            required
            disabled
            :value="source_text"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Source Multiplicity:" label-for="input-3">
          <b-form-input
            id="input-3"
            v-model="source_multiplicity"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Source Label:" label-for="input-4">
          <b-form-input
            id="input-4"
            v-model="source_label"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Target Entity:" label-for="input-5">
          <b-form-select id="input-5" v-model="target" :options="comp_tree" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Target Multiplicity:" label-for="input-6">
          <b-form-input
            id="input-6"
            v-model="target_multiplicity"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Target Label:" label-for="input-7">
          <b-form-input
            id="input-7"
            v-model="target_label"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="primary" :disabled="loading_status" @click="ok()">

        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Adding...
        </span>

        <span v-else>
          Add
        </span>

      </b-button>

      <b-button @click="cancel()">
        Cancel
      </b-button>

    </template>

  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      name: 'Associated with',
      source: '',
      source_text: '',
      source_multiplicity: '1',
      source_label: '',
      target: '',
      target_multiplicity: '*',
      target_label: '',
      rel_id: '',
      loading_status: false,
      comp_tree: [],
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
  },
  watch: {
    selected_entity2(newVal) {
      this.fillSource(newVal)
      const modelId = this.$store.state.model.id
      this.$http.get('/api/v2/domain_model/get_components_simple', { params: { model: modelId } })
        .then(({ data }) => {
          this.comp_tree = [
            {
              value: null,
              text: 'Please select an option',
              disabled: true,
            },
            ...data.map(x => ({
              value: x.id,
              text: x.name,
            })),
          ]
        })
    },
  },
  methods: {
    onShow() {
      this.fillSource(this.selected_entity2)
      const modelId = this.$store.state.model.id
      // TODO: Remove after fixed API
      this.$http.get('/api/v2/domain_model/get_components_simple', { params: { model: modelId } })
        .then(({ data }) => {
          this.comp_tree = [
            {
              value: null,
              text: 'Please select an option',
              disabled: true,
            },
            ...data.map(x => ({
              value: x.id,
              text: x.name,
            })),
          ]
        })
      // FIXME: API Call
      // this.$store.dispatch('domainModel/getOthers', payload)
      //   .then(({ data }) => {
      //     this.comp_tree = data
      //   })
    },
    fillSource(val) {
      this.source_text = val.context.details.name
      this.source = val.context.details.id
    },
    submitFunction(evt) {
      evt.preventDefault()
      this.loading_status = true
      const selectedId = this.selected_entity2.context.details.id
      const payload = {
        model: this.$store.state.model.id,
        name: this.name,
        source: this.source,
        source_multiplicity: this.source_multiplicity,
        source_label: this.source_label,
        target: this.target,
        target_multiplicity: this.target_multiplicity,
        target_label: this.target_label,
        rel_id: this.rel_id,
      }
      this.$http.post('/api/v2/domain_model/comp_rels', payload).then(() => {
        this.$store.dispatch('domainModel/selectEntity2', selectedId)
        this.loading_status = false
        this.$bvModal.hide('add-relation-modal')
      })
    },
  },
}
</script>
