<template>
  <div>
    <div id="domain-entity-context-header">
      <div class="d-inline-flex w-100 justify-content-between">
        <h3 id="domain-entity-context-selected-title" class="ml-1 text-primary">
          {{ selected_entity2.context.details.name }}
        </h3>

        <p>
          <span
            v-for="(data, index) in entityType"
            id="domain-entity-context-selected-entity-type"
            :key="index"
            class="mr-2"
          >
            <feather-icon
              v-b-tooltip.hover.above="`Type: ${data}`"
              :icon="entityLabel(data)"
              class="mr-50"
            />
            <span class="text-muted font-weight-bolder">{{ data }}</span>
          </span>
        </p>
      </div>

      <div class="d-inline-flex w-100 justify-content-around">
        <h5>Lineage:</h5>
        <div class="ml-1">
          <div v-if="selected_entity2.context.parent_rel">
            Is a
            <strong>
              {{
                selected_entity2.context.parent_rel.rel_type === "PART_OF"
                  ? "part"
                  : "type"
              }}
              of:
            </strong>
            <strong>
              <router-link
                :to="{
                  name: 'domain_ontology',
                  query: { focus: selected_entity2.context.parent_rel.parent },
                }"
              >
                {{ selected_entity2.context.parent_rel.parent_name }}
                <i class="fas fa-external-link-alt" />
              </router-link>
            </strong>
          </div>
          <div v-if="selected_entity2.context.parent_rel && findChildren().length > 0">
            Is the <strong>aggregate parent</strong> of:
            <ul>
              <li v-for="child in findChildren()" :key="child.id">
                <strong>
                  <router-link
                    :to="{
                      name: 'domain_ontology',
                      query: {
                        focus: child.id,
                      },
                    }"
                  >
                    {{ child.name }}
                    <i class="fas fa-external-link-alt" />
                  </router-link>
                </strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <hr class="mt-3">

    <!-- Quality Attribute Requirements -->
    <div class="d-inline-flex w-100 justify-content-between mb-50 px-50">
      <span class="font-medium-2 align-content-center">
        Quality Attribute Requirements
      </span>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="outline-success"
        title="Create and link a Quality Attribute Requirement / Constraint"
        data-test-id="btn-create-quality-attribute-requirement"
        @click="addConstraint"
      >
        <feather-icon icon="PlusIcon" class="text-success mr-25" />
        <span class="">Create</span>
      </b-button>
    </div>
    <div v-if="selected_entity2.constraints.length === 0">
      <p class="text-muted px-50">
        There are no quality attribute constraints for this element.
      </p>
    </div>
    <div v-else class="constraint-table-scroll">
      <b-table id="constraint_table" class="mt-50" striped hover :items="selected_entity2.constraints" :fields="c_fields">
        <template v-slot:cell(name)="data">
          <span class="text-primary"><b>{{ data.item.name }}</b></span>
          <div>{{ data.item.classification }}</div>
        </template>
        <template v-slot:cell(requirement)="data">
          <div v-if="data.item.scale && data.item.scale !== ''">
            <b>Scale: </b>{{ data.item.scale }}
          </div>
          <div v-if="data.item.must && data.item.must !== ''"><b>Must (Essential): </b>{{ data.item.must }}</div>
          <div v-if="data.item.plan && data.item.plan !== ''"><b>Plan  (Very Important): </b>{{ data.item.plan }}</div>
          <div v-if="data.item.stretch && data.item.stretch !== ''"><b>Stretch (Important): </b>{{ data.item.stretch }}</div>
          <div v-if="data.item.wish && data.item.wish !== ''"><b>Wish (Desirable): </b>{{ data.item.wish }}</div>
          <div v-if="data.item.qualifier && data.item.qualifier !== ''"><hr><b>Qualification: </b>{{ data.item.qualifier }}</div>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group size="sm">
            <b-button
              title="Generate Requirements from Quality Attribute Constraint"
              variant="flat-success"
              class="p-50"
              @click="genConstraintReqs(data.item)"
            >
              <feather-icon icon="LoaderIcon" />
            </b-button>
            <b-button
              title="Edit Quality Attribute Constraint"
              variant="flat-primary"
              class="p-50"
              @click="editConstraint(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              title="Delete Quality Attribute Constraint"
              variant="flat-danger"
              class="p-50"
              @click="deleteConstraint(data.item)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </div>

    <hr class="mt-3">

    <!-- Compliance Requirements -->
    <div class="d-inline-flex w-100 justify-content-between mb-50 px-50">
      <span class="font-medium-2 align-content-center">
        Compliance Requirements
      </span>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="outline-success"
        title="Create and link a Compliance Requirement"
        data-test-id="btn-create-compliance-requirement"
        @click="$bvModal.show('add-compliance-modal')"
      >
        <feather-icon icon="PlusIcon" class="text-success mr-25" />
        <span class="">Create</span>
      </b-button>
    </div>
    <div v-if="selected_entity2.compliance.length === 0">
      <p class="text-muted px-50">
        There are no compliance requirements for this element.
      </p>
    </div>
    <div v-else class="constraint-table-scroll">
      <b-table
        id="constraint_table"
        :items="selected_entity2.compliance"
        :fields="c_fields2"
        class="mt-25"
        striped
        hover
        thead-class="hidden"
      >
        <template v-slot:cell(name)="data">
          <strong>
            <router-link
              :to="{
                name: 'domain_ontology',
                query: {
                  focus: 'data.item.reference.id',
                },
              }"
            >
              {{ data.item.name }}
              <span v-if="data.item.reference" class="font-small-3 text-muted font-weight-bold">
                [Reference: {{ data.item.reference.qualified_name ? data.item.reference.qualified_name : data.item.reference.name }}]
              </span>
              <span
                v-else
                class="font-small-2 text-danger font-weight-bold"
                title="This Compliance Requirement has no component reference link in the database."
              >
                [ REFERENCE MISSING ]
              </span>
              <i class="fas fa-external-link-alt" />
            </router-link>
          </strong>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group size="sm">
            <b-button
              title="Generate Textual Requirement"
              variant="flat-success"
              class="p-50"
              @click="genComplianceReqs(data.item)"
            >
              <feather-icon icon="LoaderIcon" />
            </b-button>
            <b-button
              title="Edit Compliance Requirement"
              variant="flat-primary"
              class="p-50"
              @click="editCompliance(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              title="Delete Compliance Requirement"
              variant="flat-danger"
              class="p-50"
              @click="deleteCompliance(data.item)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </div>

    <hr class="mt-3">

    <!-- Requirements -->
    <div>
      <list-group-requirement
        label="Requirements"
        :entity-array="findRequirements()"
        modal="associator-generic-requirements"
      />
    </div>

    <!-- Modals -->
    <div>
      <AddConstraint />
      <EditConstraint />
      <DeleteConstraint />
      <GenerateRequirementsConstraint />
      <AddCompliance />
      <EditCompliance />
      <DeleteCompliance />
      <GenerateRequirementsCompliance />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import ListGroupRequirement from '@/components/Forms/ListGroups/ListGroupRequirement.vue'
import coreService from '@/libs/api-services/core-service'
import DeleteConstraint from '@/components/Domain/Modals/Properties/ConstraintDelete.vue'
import EditConstraint from '@/components/Domain/Modals/Properties/ConstraintEdit.vue'
import AddConstraint from '@/components/Domain/Modals/Properties/ConstraintAdd.vue'
import GenerateRequirementsConstraint from '@/components/Domain/Modals/GenerateRequirementsConstraint.vue'
import { v4 as uuidv4 } from 'uuid'
import AddCompliance from '@/components/Domain/Modals/ComplianceAdd.vue'
import EditCompliance from '@/components/Domain/Modals/ComplianceEdit.vue'
import DeleteCompliance from '@/components/Domain/Modals/ComplianceDelete.vue'
import GenerateRequirementsCompliance from '@/components/Domain/Modals/GenerateRequirementsCompliance.vue'

export default {
  components: {
    ListGroupRequirement,
    GenerateRequirementsConstraint,
    AddConstraint,
    EditConstraint,
    DeleteConstraint,
    AddCompliance,
    EditCompliance,
    DeleteCompliance,
    GenerateRequirementsCompliance,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      allModels: [],
      selectedModel: null,
      id: '',
      name: '',
      acronym: '',
      abstract: false,
      multiplicity: '',
      stereotype_selected: [],
      description: '',
      validity: 'Valid',
      context_data: { attributes: {} },
      allReqs: [],
      selectedReqs: [],
      c_fields: [
        {
          key: 'name',
          label: 'Name / Tag',
          sortable: true,
        },
        {
          key: 'gist',
          label: 'Desc.',
        },
        {
          key: 'requirement',
          label: 'Requirement',
        },
        {
          key: 'actions',
          label: '',
          thStyle: { width: '10%' },
        },
      ],
      c_fields2: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
          thStyle: { width: '10%' },
        },
      ],
      constraints: [],
      quill_editor_options: {
        theme: 'snow',
        modules: {
          toolbar: '#toolbar',
        },
      },
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
    entityType() {
      return this.selected_entity2.context.labels ? this.selected_entity2.context.labels.filter(e => e !== 'Component') : []
    },
  },
  watch: {
    selected_entity2() {
      this.selectedReqs = this.findRequirements()
    },
  },
  mounted() {
    this.$store.dispatch('domainModel/getComponents')
    this.$store.dispatch('releases/getReleases')
    this.$store.dispatch('interfaces/fetchInterfaces')
    this.populate()
  },
  methods: {
    getPlural(obj) {
      return obj?.plural || `${obj.singular.toLowerCase()}s`
    },
    getLeftOpts(obj) {
      return obj?.leftOpts === '' ? '' : obj?.leftOpts || this.getPlural(obj)
    },
    populate() {
      const modelId = this.$store.state.model.id
      const selectedId = this.selected_entity2.context.details.id
      this.$http.get('/api/v2/requirements/get_requirements_simple', { params: { model: modelId } })
        .then(({ data }) => {
          this.allReqs = data
        })
      this.selectedReqs = this.findRequirements()
    },
    entityLabel(data) {
      // Depending on the label of the Entity, return a different icon
      switch (data) {
      case 'Resource':
        return 'BookOpenIcon'
      case 'Function':
        return 'ZapIcon'
      case 'Performer':
        return 'UserIcon'
      case 'Standard':
        return 'FileTextIcon'
      case 'Reference':
        return 'EyeIcon'
      case 'Agreement':
        return 'PenToolIcon'
      case 'Capability':
        return 'CheckSquareIcon'
      case 'Activity':
        return 'ActivityIcon'
      case 'Service':
        return 'ToolIcon'
      case 'System':
        return 'ServerIcon'
      case 'Objective':
        return 'TargetIcon'
      default:
        return ''
      }
    },
    // Find related components that are children and not the parent
    findChildren() {
      const rels = this.selected_entity2.context.relationships
      const parent = this.selected_entity2.context.parent_rel

      const output = []

      // Sort through all rels, find related components
      for (let i = 0; i < rels.length; i++) {
        if (rels[i].rel_type === 'TYPE_OF' || rels[i].rel_type === 'PART_OF') {
          if (rels[i].target_props.id !== parent.parent) {
            // Find related comps that aren't parents
            output.push({
              name: rels[i].target_props.qualified_name,
              id: rels[i].target_props.id,
              rel_type: rels[i].rel_type,
            })
          }
        }
      }

      return output
    },
    // Find all relations that are requirements
    findRequirements() {
      const rels = this.selected_entity2.context.relationships

      const output = []

      // Sort through all rels, find all labeled "Issue"
      for (let i = 0; i < rels.length; i++) {
        if (rels[i].labels.includes('Requirement')) {
          output.push(rels[i].target_props)
        }
      }
      return output
    },
    async fetchSharedModels() {
      return [this.$store.state.model, ...await coreService.modelApi.getLinkedModels(this.$store.state.model.id)]
    },
    async fetchComponents(modelId, subtype) {
      await this.$store.dispatch('domainModel/getComponentsForModel', { modelId, subtype: '' })
      if (modelId && this.$store.state.model.id !== modelId) {
        // now returns all components, not just the subtype
        return this.$store.state.domainModel[modelId].components
      }
      return this.$store.state.domainModel.components
    },
    addConstraint() {
      this.$store.dispatch('domainModel/selectConstraint', null)
        .then(() => {
          const selectedId = this.selected_entity2.context.details.id
          const payload = {
            model: this.$store.state.model.id,
            name: 'New Constraint',
            id: uuidv4(),
            requirements: [],
          }
          this.$http.post(`/api/v2/domain_model/add_constraint/${selectedId}`, payload).then(async result => {
            this.loading_status = false
            this.$bvModal.hide('add-constraint-modal')
            const pl = { ...result.data, ...payload, ...{ name: '' } }
            await this.$store.dispatch('domainModel/selectConstraint', pl)
            this.$bvModal.show('edit-constraint-modal')
          })
        })
    },
    editConstraint(val) {
      this.$store.dispatch('domainModel/selectConstraint', val)
        .then(() => {
          console.log('Properties::editConstraint: ', val)
          this.$bvModal.show('edit-constraint-modal')
        })
    },
    deleteConstraint(val) {
      this.$store.dispatch('domainModel/selectConstraint', val)
        .then(() => {
          this.$bvModal.show('delete-constraint-modal')
        })
    },
    genConstraintReqs(val) {
      console.debug('Generate requirements from constraint: ', val)
      this.$store.dispatch('domainModel/selectConstraint', val)
        .then(() => {
          this.$bvModal.show('generate-constraint-requirements-modal')
        })
    },
    addCompliance() {
      this.$store.dispatch('domainModel/selectCompliance', null)
        .then(() => {
          console.log('Properties::addCompliance: ')
          const selectedId = this.selected_entity2.context.details.id
          const payload = {
            model: this.$store.state.model.id,
            name: 'New Constraint',
            id: uuidv4(),
            requirements: [],
          }
          this.$http.post(`/api/v2/domain_model/add_compliance/${selectedId}`, payload).then(async result => {
            this.loading_status = false
            this.$bvModal.hide('add-constraint-modal')
            const pl = { ...result.data, ...payload, ...{ name: '' } }
            await this.$store.dispatch('domainModel/selectConstraint', pl)
            this.$bvModal.show('edit-constraint-modal')
          })
        })
    },
    associateRequirementComponent(reqs) {
      const payload = {
        model: this.$store.state.model.id,
        requirements: reqs,
        overwrite: true,
      }
      this.$http
        .post(`/api/v2/domain_model/components/${this.selected_entity2.context.details.id}/requirements`, payload)
        .then(() => {
          this.$store.dispatch('domainModel/selectEntity2', this.selected_entity2.context.details.id)
            .then(() => {
              this.syncAllocations()
            })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Requirements associated',
              text: 'Successfully associated Requirements with the Component',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(r => {
          console.error(`[AssociateRequirementsModal] submit failed - ${r}`)
          console.error(r)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to associate Requirements',
              text: `An error occurred when attempting to associate Requirements with the Component.
              Server returned Status ${r}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    editCompliance(val) {
      this.$store.dispatch('domainModel/selectCompliance', val)
        .then(() => {
          this.$bvModal.show('edit-compliance-modal')
        })
    },
    deleteCompliance(val) {
      console.log('Properties::deleteCompliance: ', val)
      this.$store.dispatch('domainModel/selectCompliance', val)
        .then(() => {
          this.$bvModal.show('delete-compliance-modal')
        })
    },
    genComplianceReqs(val) {
      console.debug('Generate requirements from compliance: ', val)
      this.$store.dispatch('domainModel/selectCompliance', val)
        .then(() => {
          this.$bvModal.show('generate-compliance-requirements-modal')
        })
    },
  },
}
</script>
