var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-compliance-modal","title":"Create Compliance Requirement","size":"lg"},on:{"hidden":_vm.onClose,"show":_vm.onShow,"ok":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Discard ")]),_c('b-button',{attrs:{"variant":"success","disabled":_vm.isLoading || _vm.isCreating || !_vm.selectedStandard},on:{"click":function($event){return ok()}}},[(_vm.isCreating)?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" Creating Compliance Requirement... ")],1):_c('span',[_vm._v(" Create Compliance Requirement ")])])]}}])},[_c('b-form-group',{attrs:{"id":"input-comply-with","label":"Compliance with Standard / Reference","label-for":"ddl-comply-with","description":"Select an existing Standard / Reference to comply with or create a new one."}},[_c('b-form-input',{attrs:{"id":"datalist-comply-standard","disabled":_vm.isCreating,"list":"standards-list"},model:{value:(_vm.selectedStandard),callback:function ($$v) {_vm.selectedStandard=$$v},expression:"selectedStandard"}}),_c('b-form-datalist',{attrs:{"id":"standards-list"}},_vm._l((_vm.standards),function(std){return _c('option',{key:std.id},[_vm._v(" "+_vm._s(std.qualified_name)+" ")])}),0)],1),_c('b-form-group',{attrs:{"id":"input-security-classification","label":"Security Classification","label-for":"ddl-security-classification","description":"Set the security classification for this compliance requirement."}},[_c('b-form-select',{attrs:{"id":"ddl-security-classification","options":_vm.securityClassifications,"disabled":_vm.isCreating,"required":""},model:{value:(_vm.selectedSecurityClassification),callback:function ($$v) {_vm.selectedSecurityClassification=$$v},expression:"selectedSecurityClassification"}})],1),_c('hr',{staticClass:"mt-2"}),_c('list-group-requirement',{attrs:{"label":"Requirements","entity-array":_vm.selectedRequirements,"modal":"associator-generic-requirements-edit"}}),_c('associator-generic',{attrs:{"name":"Requirements","suffix":"-edit","associated-items":_vm.selectedRequirements.map(function (item) {
      return {
        value: {
          id: item.id,
          toSortBy: item.display_id,
        },
        text: ((item.display_id) + ". " + (item.object_text.replace(/<\/?[^>]+(>|$)/g, ''))),
      }
    }),"all-items":_vm.allRequirements.map(function (item) {
      return {
        value: {
          id: item.id,
          toSortBy: item.display_id,
        },
        text: ((item.display_id) + ". " + (item.text.replace(/<\/?[^>]+(>|$)/g, ''))),
      }
    })},on:{"associated":_vm.onRequirementsLinked}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }