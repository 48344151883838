<template>
  <v-select
    v-model="defaultSpec"
    placeholder="-- Select Specification --"
    label="title"
    :options="specList"
    :value="value"
    @input="onInput"
  >
    <template #option="{ title, prefix }">
      <div class="d-flex justify-content-between">
        <span>{{ title }}</span>
        <span class="font-small-3 text-muted">{{ prefix }}</span>
      </div>
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'SpecificationPicker',
  components: {
    vSelect,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      defaultSpec: this.$store.state.requirementsTableLegacy.specification ? this.$store.state.specification.list.find(({ id }) => id === this.$store.state.requirementsTableLegacy.specification) : null,
    }
  },
  computed: {
    specList() {
      return this.$store.state.specification.list
    },
  },
  mounted() {
    this.$store.dispatch('specification/getAllSpecifications')
    if (this.defaultSpec) {
      this.$emit('input', this.defaultSpec)
    }
  },
  methods: {
    onInput(event) {
      this.$emit('input', event)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
